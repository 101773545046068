import { ResourceEnum, RoutePaths, ScopeEnum } from "config";

export const routeToResourceMap = {
  [RoutePaths.USER_MANAGEMENT]: ResourceEnum.User,
  [RoutePaths.SOURCE_EXCHANGE]: ResourceEnum.SourceExchange,
  [RoutePaths.TAG]: ResourceEnum.Tag,
  [RoutePaths.DOCUMENT]: ResourceEnum.DocumentList,
  [RoutePaths.PRODUCT_FUND]: ResourceEnum.ProductFund,
  [RoutePaths.PROFILES]: ResourceEnum.InvestorProfile,
  [RoutePaths.INVESTMENT_CAPITAL_MOVEMENT]: ResourceEnum.CapitalMovement,
  [RoutePaths.INVESTMENT_CAPITAL_MOVEMENT_DIVIDEND]:
    ResourceEnum.CapitalMovementDividend,
  [RoutePaths.ASSETS_FIREBLOCKS]: ResourceEnum.AssetsFireblocks,
  [RoutePaths.ASSETS_EXCHANGE]: ResourceEnum.AssetsAsset,
  [RoutePaths.REPORTING]: ResourceEnum.InvestmentData,
  [RoutePaths.PRODUCT_FUND_REPORTING]: ResourceEnum.InvestorData,
  [RoutePaths.EXPECTED_CONVERSION]: ResourceEnum.LeadConversionExpectation,
  [RoutePaths.NOTE_TYPE]: ResourceEnum.NoteType,
  [RoutePaths.KPI_PERFORMANCE]: ResourceEnum.KpiData,
  [RoutePaths.PRODUCT_FUND_SERIES]: ResourceEnum.ProductFundSeries,
  [RoutePaths.SNAPSHOTS]: ResourceEnum.Snapshot,
  [RoutePaths.WESTEROS_TAGS]: ResourceEnum.WesterosTag,
  [RoutePaths.WESTEROS_DOCUMENT_LIST]: ResourceEnum.WesterosDocumentList,
  [RoutePaths.WESTEROS_NOTE_TYPE]: ResourceEnum.WesterosNoteType,
  [RoutePaths.WESTEROS_SERVICE]: ResourceEnum.WesterosService,
  [RoutePaths.CLIENT_DATABASE]: ResourceEnum.WesterosClientProfile,
  [RoutePaths.ADVISORY_PROJECT]: ResourceEnum.AdvisoryProject,
  [RoutePaths.LINK_AGENT_PROFILES]: ResourceEnum.LinkAgentProfile,
  [RoutePaths.WEEKLY_UPDATES]: ResourceEnum.WeeklyUpdate,
  [RoutePaths.ANNOUNCEMENTS]: ResourceEnum.Announcement,
  [RoutePaths.UPCOMING_EVENTS]: ResourceEnum.UpcomingEvent,
  [RoutePaths.STATEMENTS]: ResourceEnum.Statement,
  [RoutePaths.REPORTS]: ResourceEnum.Report,
  [RoutePaths.STATEMENT_TYPE]: ResourceEnum.StatementType,
  [RoutePaths.REPORT_TYPE]: ResourceEnum.ReportType,
  [RoutePaths.EMAILS]: ResourceEnum.EmailHistory,
  [RoutePaths.EMAILS_SEND_EMAIL]: ResourceEnum.InvestorProfile,
  [RoutePaths.CAPITAL_INTRODUCER_PAYOUTS]: ResourceEnum.LinkAgentPayout,
  [RoutePaths.CAPITAL_INTRODUCER_REPORT]: ResourceEnum.LinkAgentReport,
  [RoutePaths.CAPITAL_INTRODUCER_KPI_REPORT]: ResourceEnum.LinkAgentKpiData,
  [RoutePaths.VENTURE_NOTE_TYPE]: ResourceEnum.VentureNoteType,
  [RoutePaths.VENTURE_TAGS]: ResourceEnum.VentureTag,
  [RoutePaths.VENTURE_DOCUMENT_LIST]: ResourceEnum.VentureDocumentList,
  [RoutePaths.ADMIN_RESOURCES]: ResourceEnum.Resource,
  [RoutePaths.VENTURE_SECTOR]: ResourceEnum.VentureSector,
  [RoutePaths.ADMIN_EMAIL_TEMPLATES]: ResourceEnum.EmailTemplate,
  [RoutePaths.VENTURE_ORIGINATION_SOURCE]:
    ResourceEnum.VentureOriginationSource,
  [RoutePaths.VENTURE_BLOCKCHAIN_LIST]: ResourceEnum.VentureBlockchain,
  // [RoutePaths.VENTURE_PROJECT_TYPE_LIST]: ResourceEnum.VentureProjectType,
  [RoutePaths.VENTURE_INVESTMENT_METHOD]: ResourceEnum.VentureInvestmentMethod,
  [RoutePaths.VENTURE_PROJECT_PROFILE]: ResourceEnum.ProjectProfile,
  [RoutePaths.VENTURE_CONTACT_TYPE]: ResourceEnum.VentureContactType,
  [RoutePaths.VENTURE_DASHBOARD]: ResourceEnum.VentureDashboard,
  [RoutePaths.VENTURE_VALUATION_CONTROL]: ResourceEnum.VentureValuationControl,
  [RoutePaths.VENTURE_ASSET_ATTRIBUTION]: ResourceEnum.VentureAssetAttribution,
  [RoutePaths.VENTURE_CONTACT]: ResourceEnum.VentureContact,
  [RoutePaths.VENTURE_KPI]: ResourceEnum.VentureKpi,
  [RoutePaths.SYSTEM_TASK_MANAGEMENT_TASK]:
    ResourceEnum.SystemTaskManagementTask,
  [RoutePaths.SYSTEM_TASK_MANAGEMENT_RECURRING_TASK]:
    ResourceEnum.SystemTaskManagementRecurringTask,
  [RoutePaths.SYSTEM_TASK_MANAGEMENT_TASK_TYPE]:
    ResourceEnum.SystemTaskManagementTaskType,
  [RoutePaths.SYSTEM_TASK_MANAGEMENT_NOTE_TYPE]:
    ResourceEnum.SystemTaskManagementTaskNoteType,
  [RoutePaths.SYSTEM_TASK_MANAGEMENT_TASK_REPORTING]:
    ResourceEnum.SystemTaskManagementTaskReporting,
  [RoutePaths.ADVISORY_BUYER]: ResourceEnum.AdvisoryBuyer,
  [RoutePaths.ADVISORY_PURCHASE]: ResourceEnum.AdvisoryPurchase,
  [RoutePaths.ADVISORY_TRANCHE]: ResourceEnum.AdvisoryTranche,
  [RoutePaths.ADVISORY_EXIT_PLAN]: ResourceEnum.AdvisoryExitPlan,
  [RoutePaths.ADVISORY_DISTRIBUTION]: ResourceEnum.AdvisoryDistribution,
  [RoutePaths.ADVISORY_REPORT]: ResourceEnum.AdvisoryReport,
  [RoutePaths.FIREBLOCKS_WALLET]: ResourceEnum.FireblocksWallet,
  [RoutePaths.EMPLOYEE_DASHBOARD]: ResourceEnum.EmployeeDashboard,
} as Record<RoutePaths, ResourceEnum>;

export const routeToSpecificScopeMap = {
  [RoutePaths.EMAILS_SEND_EMAIL]: ScopeEnum.SendEmail,
} as Record<RoutePaths, ScopeEnum>;
