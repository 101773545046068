import {
  CountryPhoneCodeListDocument,
  UserDeleteDocument,
  UserContactDetailsFetchDocument,
  RoleListDocument,
  UserUpdateDocument,
  UserGetByIdDocument,
  UserCreateDocument,
  UserGetInvestorDetailsForCreateDocument,
  UserUpdateOwnAccountDocument,
  UsersFilterDocument,
  UsersListDocument,
  InvestorProfilesForUserCreationDocument,
  UserOtpResetDocument,
  UserListCategoriesDocument,
  UserListUsersCouldBeManagedDocument,
  DepartmentListDocument,
  DepartmentListDepartmentsCouldBeManagedDocument,
  UserListByDepartmentDocument,
  UserListByRoleDocument,
  UserGetByManagedDepartmentDocument,
} from "api/generated";
import {
  NotificationDuration,
  NotificationMode,
  showSnackBar,
  store,
} from "store";
import { successMessages } from "api/messages/success";
import {
  DepartmentEnum,
  Optional,
  PartialSelectedAndNullable,
  ResourceEnum,
  ScopeEnum,
  mapInvestorName,
} from "config";

import { RequestTypes } from "../enums";
import { getRequestInstance } from "../utils";
import {
  IGetUserReturnType,
  ICountryPhoneCode,
  ICreateUserArguments,
  IRole,
  GetUserByIdReturnType,
  IUpdateUserArgs,
  IUserModel,
  IGetUserListArgs,
  UserContactInfo,
  IUpdateOwnUserArgs,
  InvestorProfileDetailsForUserModel,
  IUsersFilterResponse,
  IDepartmentModel,
  ICategoryModel,
} from "./types";
import { InvestorProfilesNameReturnType } from "../investment/investor-profile/types";

export async function createUserRequest(
  variables: PartialSelectedAndNullable<
    ICreateUserArguments,
    | "line"
    | "discord"
    | "twitter"
    | "telegram"
    | "slack"
    | "whatsapp"
    | "linkedin"
    | "title"
    | "environment"
  >
) {
  try {
    const request = getRequestInstance(RequestTypes.MUTATE);
    const res = await request({
      mutation: UserCreateDocument,
      variables,
    });
    store.dispatch(
      showSnackBar({
        duration: NotificationDuration.DEFAULT,
        message: successMessages.newUser,
        mode: NotificationMode.SUCCESS,
      })
    );
    return res?.data?.userCreate;
  } catch (error) {
    throw error;
  }
}

export async function getCountriesPhoneCode(): Promise<ICountryPhoneCode[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: CountryPhoneCodeListDocument,
    });
    return res?.data?.countryPhoneCodeList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getRolesList(): Promise<IRole[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: RoleListDocument,
    });
    return res?.data?.roleList ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getUsersList(
  variables: IGetUserListArgs
): Promise<IGetUserReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UsersListDocument,
      variables,
    });
    return res?.data?.usersPaginated;
  } catch (error) {
    throw error;
  }
}

export async function getUserById(
  userId: number
): Promise<GetUserByIdReturnType> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserGetByIdDocument,
      variables: {
        userId,
      },
    });
    if (!res?.data?.userGetById) {
      return res?.data?.userGetById;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...data } = res.data.userGetById;
    return data;
  } catch (error) {
    throw error;
  }
}

export async function deleteUser(userId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UserDeleteDocument,
      variables: {
        userId,
      },
    });
    return Boolean(res?.data?.userDelete);
  } catch (error: any) {
    store.dispatch(
      showSnackBar({
        duration: NotificationDuration.ERRORS,
        message: error.message,
        mode: NotificationMode.ERROR,
      })
    );
    throw error;
  }
}

export async function updateUser(
  variables: IUpdateUserArgs
): Promise<Optional<Pick<IUserModel, "userId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UserUpdateDocument,
      variables,
    });
    return res?.data?.userUpdate;
  } catch (error) {
    throw error;
  }
}

export async function getUserContactInfo(
  userId: number
): Promise<UserContactInfo> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserContactDetailsFetchDocument,
      variables: {
        userId,
      },
    });
    return res?.data?.userContactDetailsFetch;
  } catch (error) {
    throw error;
  }
}

export async function updateUserOwnAccount(
  variables: IUpdateOwnUserArgs
): Promise<Optional<Pick<IUserModel, "userId">>> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UserUpdateOwnAccountDocument,
      variables,
    });
    return res?.data?.userUpdateOwnAccount;
  } catch (error) {
    throw error;
  }
}

export async function getInvestorProfileDetailsForUser(
  investorProfileId: number
): Promise<InvestorProfileDetailsForUserModel> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserGetInvestorDetailsForCreateDocument,
      variables: { investorProfileId },
    });
    return res?.data?.userGetInvestorDetailsForCreate;
  } catch (error) {
    throw error;
  }
}

export async function getUsersFilter(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<IUsersFilterResponse[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UsersFilterDocument,
      variables: {
        scope,
        resource,
      },
    });
    return res?.data?.userListForFilters ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getUsersFinancialFullAccess(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<IUserModel, "userId" | "firstName" | "lastName">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserListByRoleDocument,
      variables: {
        scope,
        resource,
        roleName: "Finance Access_Full",
      },
    });
    return res?.data?.userListByRole ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getUserHOV(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<Pick<IUserModel, "userId">> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserGetByManagedDepartmentDocument,
      variables: {
        scope,
        resource,
        departmentName: DepartmentEnum.VENTURE,
      },
    });
    return res?.data?.userGetByManagedDepartment;
  } catch (error) {
    throw error;
  }
}

export async function getUsersByDepartment(
  resource: ResourceEnum,
  scope: ScopeEnum,
  departmentId: number
): Promise<Pick<IUserModel, "userId" | "firstName" | "lastName">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserListByDepartmentDocument,
      variables: {
        scope,
        resource,
        departmentId,
      },
    });
    return res?.data?.userListByDepartment ?? [];
  } catch (error) {
    throw error;
  }
}

export async function getInvestorProfilesForUsersCreate(): Promise<
  Omit<InvestorProfilesNameReturnType, "fileNumber">[]
> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: InvestorProfilesForUserCreationDocument,
    });
    return (res?.data?.investorProfilesForUserCreation ?? []).map(
      mapInvestorName
    );
  } catch (error) {
    throw error;
  }
}

export async function resetUserOtp(userId: number): Promise<boolean> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.MUTATE);
    const res = await makeRequest({
      mutation: UserOtpResetDocument,
      variables: {
        userId,
      },
    });
    return Boolean(res?.data?.userOtpReset);
  } catch (error) {
    throw error;
  }
}

export async function getDepartments(
  resource: ResourceEnum,
  scope: ScopeEnum
): Promise<IDepartmentModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: DepartmentListDocument,
      fetchPolicy: "cache-first",
      variables: {
        resource,
        scope,
      },
    });
    return res?.data?.departmentList ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getDepartmentsForUserManagementSettings(
  userId?: number
): Promise<IDepartmentModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: DepartmentListDepartmentsCouldBeManagedDocument,
      variables: {
        userId,
      },
    });
    return res?.data?.departmentListDepartmentsCouldBeManaged ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getUsersForUserManagementSettings(
  userId?: number
): Promise<Pick<IUsersFilterResponse, "userId" | "fullName">[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserListUsersCouldBeManagedDocument,
      variables: {
        userId,
      },
    });
    return res?.data?.userListUsersCouldBeManaged ?? [];
  } catch (e) {
    throw e;
  }
}

export async function getCategories(): Promise<ICategoryModel[]> {
  try {
    const makeRequest = getRequestInstance(RequestTypes.QUERY);
    const res = await makeRequest({
      query: UserListCategoriesDocument,
      fetchPolicy: "cache-first",
    });
    return res?.data?.userListCategories ?? [];
  } catch (e) {
    throw e;
  }
}
