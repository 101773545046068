import { createSlice } from "@reduxjs/toolkit";
import { ISidebarSlideState } from "./types";

const initialState: ISidebarSlideState = {
  isSidebarExpanded: false,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebarExpanded: (state) => {
      state.isSidebarExpanded = !state.isSidebarExpanded;
    },
  },
});

export const { toggleSidebarExpanded } = sidebarSlice.actions;
export default sidebarSlice.reducer;
